import { useMediaQuery } from 'react-responsive';
import { useEffect, useState } from 'react';
import { breakpoints } from '../../constants';

const useIsMinWidth = breakpoint => {
  const [hydrated, setHydrated] = useState(false);

  useEffect(() => {
    setHydrated(true);
  }, []);

  const IsMinWidth = useMediaQuery(
    {
      query: `(min-width: ${breakpoints[breakpoint]})`,
    },
    hydrated ? undefined : { deviceWidth: 1600 }
  );
  return IsMinWidth;
};

export { useIsMinWidth };
